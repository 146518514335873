import { isArray, isUndefined } from "lodash";
import { WidgetData } from "src/modules/widget/models/data";
import { WidgetOption } from "src/modules/widget/models/widget";
import { Chart } from "react-google-charts";


const View: React.FC<{
  data: WidgetData;
  option: WidgetOption;
  height?: number
  width?: number
}> = (props) => {
  let { option, data, height, width } = props

  let dataChart = [] as any[];

  let listChart = data.list;
  if (isUndefined(data.list) || data.list == null || !isArray(data.list)) {
    data.list = []
  }

  /* 
    item = {
          "device_id": "541036792090984459",
          "field_id": "0",
          "is_number": true,
          "key": "temperature",
          "model_id": "541036982478831627",
          "source_input": "TELEMETRY",
          "time": "2024-11-20T08:14:49Z",
          "value": "28.799999",
          "value_n": 28.799999237060547
      },
  */
  // get field list

  const xField = option.x_field || "time";
  const keyField = option.series_field || "key";
  const yField = option.y_field || "value_n";

  let listTitle: Set<String> = new Set();
  let mapData: Map<String, any[]> = new Map();
  for (const item of listChart) {
    const raw = item as any;
    const field = raw[keyField];
    listTitle.add(field);
    const timeStr = raw[xField];
    mapData.set(timeStr, []);
  }

  const arrryList = Array.from(listTitle);
  arrryList.unshift(xField);

  // map data
  for (const item of listChart) {
    const raw = item as any;
    const field = raw[keyField];
    const timeStr = raw[xField];
    const time = new Date(timeStr);
    if (mapData.has(field)) {
      const arrayData = mapData.get(field) as any[];
      const index = arrryList.indexOf(field);
      arrayData[index] = raw[yField];
      mapData.set(field, arrayData);
    } else {
      const arrayData: any[] = [];
      listTitle.forEach((field) => {
        arrayData.push(0);
      });
      const index = arrryList.indexOf(field);
      arrayData[0] = time;
      arrayData[index] = raw[yField];
      mapData.set(timeStr, arrayData);
    }
  }

  dataChart = [arrryList];
  mapData.forEach((values, key) => {
    dataChart.push(values);
  });


  const options = {
    curveType: "function",
    legend: 'none',
    hAxis: {
      format: "HH:mm:ss", // Custom date format
      gridlines: { count: 10 }, // Controls the number of gridlines
    },
    chartArea: { top: 20, right: 0, bottom: 30, left: 50 },
  };

  if (dataChart.length < 2) {
    return <div style={{ textAlign: "center" }}>No data</div>;
  }

  return (
    <Chart
      chartType="LineChart"
      data={dataChart}
      options={options}
      formatters={[
        {
          column: 0,
          type: "DateFormat",
          options: {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Local time zone
          },
        },
      ]}
      height={height}
      width={width}
      render={({ renderControl, renderChart }) => {
        return (
          <div
            style={{ display: "flex", height: "100%", flexDirection: "column" }}
          >
            <div>{renderControl(() => true)}</div>
            <div>{renderChart()}</div>
          </div>
        );
      }}
    />
  );
};

export default View;

