import { isArray, isUndefined } from "lodash";
import React from "react";
import { WidgetData } from 'src/modules/widget/models/data';
import { WidgetOption } from 'src/modules/widget/models/widget';
import { Chart } from "react-google-charts";


const View: React.FC<{
  data: WidgetData
  option: WidgetOption
  height?: number
  width?: number
}> = (props) => {

  let {  data, option, height, width } = props

  // const text = option.text || "";
  const sourceKey = option.source_key || "list";
  const keyField = option.data_key || "key";
  const valueField = option.data_val || "value_n";
  // const unitData = option.unit || "";

  let maxValue = option.max || 100;
  let minValue = option.min || 0;

  try {
    maxValue = parseFloat(`${maxValue}`);
  } catch(e) {
  }

  try {
    minValue = parseFloat(`${minValue}`);
  } catch(e) {
  }

  let dataChart = [] as any[];

  let listChart = data[sourceKey];
  if (isUndefined(data[sourceKey]) || data[sourceKey] == null || !isArray(data[sourceKey])) {
    listChart = []
  }

  let mapData: Map<String, any> = new Map();
  for (const item of listChart) {
    const raw = item as any;
    const field = raw[keyField];
    const value = raw[valueField];
    mapData.set(field, value);
  }

  const options = {
    // redFrom: 90,
    // redTo: 100,
    // yellowFrom: 75,
    // yellowTo: 90,
    minorTicks: 5,
    max: maxValue,
    min: minValue,
  };

  dataChart =  [
    ["Label", "Value"]
  ]

  mapData.forEach((value, key) => {
    dataChart.push([key, value])
  });
  
  return (
    <Chart
      chartType="Gauge"
      width={width}
      height={height}
      data={dataChart}
      options={options}
      style={{
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "center",
      }}

    />
  );
}

export default View;