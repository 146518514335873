import {
  RollbackOutlined,
  SaveOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, message, Row, Spin } from "antd";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import { UiDetail, UiInput, WidgetDetail } from "src/types";
import EditWidget from "./edit-widget";
import WidgetBoxGrid from "src/modules/widget/components/widget-box/widget-box-grid";
import "../../../../node_modules/react-grid-layout/css/styles.css";
import GetWidget from "src/modules/widget/components/get-widget";
import FormSaveUi from "./form-save-ui";
import { ThunkResult, useAppDispatch } from "src/app/hooks";
import { createUiThunkAction, updateUiThunkAction } from "../thunk";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export const WidgetItem: React.FC<{
  id: any;
  label: string;
  onEdit: () => void;
  onDelete: () => void;
}> = (props) => {
  const [widget, setWidget] = useState<WidgetDetail>();
  return (
    <GetWidget
      id={props.id}
      onSuccess={(detail) => {
        if (detail) {
          setWidget(detail);
        }
      }}
      style={{ height: "100%" }}
    >
      <WidgetBoxGrid widget={widget} label={props.label}>
        <Row
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Col>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="edit"
                    icon={<EditOutlined />}
                    onClick={() => {
                      props.onEdit();
                    }}
                  >
                    Edit
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    key="delete"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      props.onDelete();
                    }}
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <Button
                type="text"
                icon={<MoreOutlined />}
                onClick={(e) => e.preventDefault()}
              />
            </Dropdown>
          </Col>
        </Row>
      </WidgetBoxGrid>
    </GetWidget>
  );
};

const GridLayoutEdit: React.FC<{
  detail?: UiDetail | null;
  onBack?: () => void;
  onSuccess?: () => void;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { detail } = props;

  const [layouts, setLayouts] = useState<{
    widgets: any;
    labels: any;
    lg: Layout[];
  }>({
    widgets: {},
    labels: {},
    lg: [],
  });

  const [modalName, setModalName] = useState(false);
  const [editWidget, setEditWidget] = useState(false);
  const [widget, setWidget] = useState<{
    id: string;
    widgetId: any;
    name: string;
  }>({
    id: "",
    name: "",
    widgetId: "",
  });

  useEffect(() => {
    if (detail) {
      const layoutsNew: {
        widgets: any;
        labels: any;
        lg: Layout[];
      } = {
        widgets: {},
        labels: {},
        lg: [],
      };
      if (detail.layouts) {
        for (const item of detail.layouts) {
          if (!item) {
            continue;
          }
          layoutsNew.lg.push({
            ...item,
          });

          layoutsNew.widgets[`${item.i}`] = detail.widgets[`${item.i}`];
          layoutsNew.labels[`${item.i}`] = detail.labels[`${item.i}`];
        }
      }

      setLayouts({
        ...layoutsNew,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  const generateDOM = () => {
    return _.map(layouts.lg, (l, i) => {
      const widgetId = layouts.widgets[l.i];
      if (widgetId === undefined) {
        return null;
      }
      return (
        <div key={l.i} data-grid={l}>
          <WidgetItem
            id={widgetId}
            label={layouts.labels[l.i]}
            onEdit={() => {
              onEditWidget(l.i);
            }}
            onDelete={() => {
              deleteWidget(l.i);
            }}
          />
        </div>
      );
    });
  };

  const onLayoutChange = (layout: any, layouts: any) => {
    setLayouts({
      ...layouts,
    });
  };

  const onAddWidget = () => {
    setEditWidget(true);
    setWidget({
      id: "",
      name: "",
      widgetId: "",
    });
  };

  const onEditWidget = (id: string) => {
    setEditWidget(true);
    setWidget({
      name: layouts.labels[id],
      widgetId: layouts.widgets[id],
      id,
    });
  };

  const onSaveWidget = ({ name, widgetId }: any) => {
    const layoutsNew = Object.assign({}, layouts);
    let id = moment().format("X");
    if (widget.id === "") {
      widget.id = id;
      layoutsNew.lg.push({
        x: 0,
        y: 0,
        w: 3,
        h: 30,
        i: id,
      });
    } else {
      id = widget.id;
    }

    layoutsNew.widgets[`${id}`] = widgetId;
    layoutsNew.labels[`${id}`] = name || "";

    setLayouts({
      ...layoutsNew,
    });

    setEditWidget(false);
  };

  const deleteWidget = (id: any) => {
    const layoutsNew = Object.assign({}, layouts);
    layoutsNew.lg = layoutsNew.lg.filter((item) => item.i !== id);

    layoutsNew.widgets[`${id}`] = undefined;
    layoutsNew.labels[`${id}`] = undefined;

    setLayouts({
      ...layoutsNew,
    });
  };

  const onSaveDashboard = () => {
    setModalName(true);
  };

  const onSaveUI = async ({ name, is_home }: any) => {
    const uiModel: UiInput = {
      name: name,
      labels: {},
      layouts: layouts.lg.map((item) => {
        return {
          h: item.h,
          w: item.w,
          i: item.i,
          x: item.x,
          y: item.y,
        };
      }),
      widgets: {},
      is_home: is_home || false,
      filters: "{}",
      type: "grid",
    };
    for (const item of layouts.lg) {
      uiModel.widgets[item.i] = layouts.widgets[item.i] as string;
      uiModel.labels[item.i] = layouts.labels[item.i] as string;
    }
    let rs: ThunkResult<boolean, any>;
    setModalName(false);
    setLoading(true);
    if (props.detail) {
      rs = await dispatch<any>(
        updateUiThunkAction({
          id: props.detail.id,
          input: uiModel,
        })
      );
    } else {
      rs = await dispatch<any>(createUiThunkAction(uiModel));
    }
    setLoading(false);
    if (rs) {
      if (rs.error) {
        message.error(rs.error.message);
        return;
      } else {
        message.success("Save ui success!");

        if (props.onSuccess) {
          props.onSuccess();
        }
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          <Row
            gutter={[10, 10]}
            style={{
              flexDirection: "column",
              position: "fixed",
              bottom: "12px",
              right: "12px",
              zIndex: 1000,
            }}
          >
            <Col>
              <Button
                style={{ backgroundColor: "green", borderColor: "green" }}
                size="large"
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={onAddWidget}
              />
            </Col>
            <Col>
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<SaveOutlined />}
                onClick={onSaveDashboard}
              />
            </Col>
            <Col>
              <Button
                size="large"
                type="primary"
                style={{ backgroundColor: "red", borderColor: "red" }}
                shape="circle"
                icon={<RollbackOutlined />}
                onClick={() => {
                  if (props.onBack) {
                    props.onBack();
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <ResponsiveReactGridLayout
            layouts={layouts}
            onLayoutChange={onLayoutChange}
            // WidthProvider option
            measureBeforeMount={false}
            // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
            // and set `measureBeforeMount={true}`.
            useCSSTransforms={true}
            compactType="vertical"
            margin={[2, 2]}
            containerPadding={[5, 0]}
            rowHeight={10}
          >
            {generateDOM()}
          </ResponsiveReactGridLayout>
        </Col>
        <EditWidget
          new={widget.id === ""}
          visible={editWidget}
          widget={{ ...widget }}
          onSave={onSaveWidget}
          onCancel={() => {
            setEditWidget(false);
          }}
        />
        <FormSaveUi
          new={props.detail == null}
          visible={modalName}
          ui={{
            name: props.detail?.name || "",
            is_home: props.detail?.is_home || false,
          }}
          onSave={onSaveUI}
          onCancel={() => {
            setModalName(false);
          }}
        />
      </Row>
    </Spin>
  );
};

export default GridLayoutEdit;
