
import LineWidget from './widget-line-chart';
import ColumnWidget from './widget-column-chart';
import PieWidget from './widget-pie-chart';
import AreaWidget from './widget-area-chart';
import GaugeWidget from './widget-gauge-chart';
import ProcessGaugeWidget from './widget-process/gauge';
import ProcessLiquidWidget from './widget-process/liquid';
import TinyRingProgessWidget from './widget-tiny-chart/ringprogess';
import FormattersColorFormatWidget from './widget-formatters/colorFormat';
import TableWidget from './widget-table-chart';
import { WidgetComponent } from "src/modules/widget/models/widget";

var widgets = new Map<string, WidgetComponent>()
widgets.set(LineWidget.type, LineWidget)
widgets.set(ColumnWidget.type, ColumnWidget)
widgets.set(ProcessGaugeWidget.type, ProcessGaugeWidget)
widgets.set(PieWidget.type, PieWidget)
widgets.set(AreaWidget.type, AreaWidget)
widgets.set(TinyRingProgessWidget.type, TinyRingProgessWidget)
widgets.set(TableWidget.type, TableWidget)
widgets.set(FormattersColorFormatWidget.type, FormattersColorFormatWidget)
widgets.set(ProcessLiquidWidget.type, ProcessLiquidWidget)
widgets.set(GaugeWidget.type, GaugeWidget)

function GetWidgets(): Map<string, WidgetComponent> {
    return widgets
}

export {
    LineWidget,
    ColumnWidget,
    ProcessGaugeWidget,
    PieWidget,
    AreaWidget,
    TinyRingProgessWidget,
    TableWidget,
    FormattersColorFormatWidget,
    ProcessLiquidWidget,
    GaugeWidget,
    GetWidgets
}