import { WidgetComponent } from "src/modules/widget/models/widget"
import EditGauge from "./edit-gauge"
import ViewGauge from "./view-gauge"


const wiget: WidgetComponent = {
    type: "gauge",
    edit: EditGauge,
    icon: "gauge-chart",
    name: "Gauge Chart",
    view: ViewGauge,
    options: [],
}

export default wiget