import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { RootState } from 'src/app/store'
import { ModelLog, ListPaginationModelLogInput, Model } from 'src/types'
import { geListModeIdHaveLogByDeviceIdThunkAction, getListPaginationModelLogThunkAction } from './thunk'

export const ModelLogSlice = createSlice({
    name: 'model_log',
    initialState: {
        loading: false,
        message: "",
        models: [] as Model[],
        list: [] as ModelLog[],
        last_time: [] as ModelLog[],
        by_time: [] as ModelLog[],
        filter: {
            limit: 50,
            end_time: moment().toISOString(),
            start_time: moment().subtract(10, "minute").toISOString(),
            model_id: "",
            device_id: "",
        } as ListPaginationModelLogInput
    },
    reducers: {
        setFilter: (state, payload: PayloadAction<ListPaginationModelLogInput>) => {
            state.filter = payload.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(geListModeIdHaveLogByDeviceIdThunkAction.fulfilled, (state, action) => {
            state.models = action.payload.geListModeIdHaveLogByDeviceId as Model[]
        })
        builder.addCase(getListPaginationModelLogThunkAction.pending, (state, action) => {
            state.filter = action.meta.arg
            state.loading = true
            state.message = ""
            state.list = []
        })
        builder.addCase(getListPaginationModelLogThunkAction.rejected, (state, action) => {
            state.loading = false
            state.message = ""
        })
        builder.addCase(getListPaginationModelLogThunkAction.fulfilled, (state, action) => {
            state.loading = false
            state.message = ""
            if (action.payload.getListPaginationModelLog != null) {
                let list =  action.payload.getListPaginationModelLog?.data as ModelLog[]
                if (list) {
                    list = list.sort((a, b) => moment(b.time).diff(moment(a.time)))
                    state.list = list.map((item, index) => {
                        return Object.assign({}, item, { 
                            id: index,
                            ts: moment(item.time).unix(),
                        }) as ModelLog
                    })
                    return
                } 
            }
            state.list = []
        })
    }
})

export default ModelLogSlice.reducer
export const { setFilter } = ModelLogSlice.actions


// select
export const selectModelLog = (state: RootState) => state.model_log
export const selectLoading = createSelector(selectModelLog, (state) => state.loading)
export const selectMessage = createSelector(selectModelLog, (state) => state.message)
export const selectFilter = createSelector(selectModelLog, (state) => state.filter)
export const selectListModelLog = createSelector(selectModelLog, (state) => state.list)
export const selectLastTimeModelLog = createSelector(selectModelLog, (state) => state.last_time)
export const selectByTimeModelLog = createSelector(selectModelLog, (state) => state.by_time)
export const selectModelDevices = createSelector(selectModelLog, (state) => state.models)